<template>
	<div class="information">
		<div class="service-right-wrap">
			<div class="title title-vertical flex-space-between">
				<span>资料下载</span>
			</div>
			<List :isDown="isDown"></List>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {postTitle} from '@/service/api/index'
import List from '@/components/list/list.vue'

export default {
	components: {List},
	data() {
		return {
			isDown: 1
		}
	},

	mounted() {
		this.postTitle()
	},

	methods: {
		postTitle() {
			postTitle({
				id: 1,
				type: 1
			}).then((res) => {
				// console.log(res)
				EventBus.$emit('pathArr', res.msg)
			})
		}
	}
}
</script>

<style lang="less" scoped></style>
